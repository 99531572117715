import { useRoutes } from 'react-router-dom';
import router from 'src/router';
import './styles/index.css';

import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';

import { CssBaseline, Dialog, DialogTitle } from '@mui/material';
import ThemeProvider from './theme/ThemeProvider';
import { useIdleTimer } from 'react-idle-timer';
import React, { useState, useCallback, useEffect } from 'react';
import AuthService from './services/AuthService';

import { useDispatch } from 'react-redux';
import { logout } from "./redux/userRedux";
import { useNavigate } from 'react-router-dom';

function App() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const content = useRoutes(router);
  const [showWarning, setShowWarning] = useState(false);
  const [countdown, setCountdown] = useState(120); // Countdown starts from 60 seconds

  const handleOnIdle = useCallback(() => {
    setShowWarning(true);
    setCountdown(120); // Reset countdown when warning dialog is shown
    console.log('User is idle');
  }, []);

  const handleOnActive = useCallback(() => {
    setShowWarning(false);
    setCountdown(120); // Reset countdown when user becomes active
    console.log('User is active');
  }, []);

  const handleLogout = useCallback(() => {
    setShowWarning(false);
    setCountdown(120); // Reset countdown when logging out
    console.log('User has been logged out due to inactivity');
    Redirectlogout();
  }, []);

  const Redirectlogout = () => {
    AuthService.logout()
    .then((response) => {
        dispatch(logout());
        navigate("/");
    });
  };

  // Initialize the idle timer hook with options
  useIdleTimer({
    timeout: 1000 * 60 * 2, // 10 minutes to show warning
    onIdle: handleOnIdle,
    onActive: handleOnActive,
    debounce: 500,
  });

  // Countdown effect for auto-logout after 1 minute of showing the warning
  useEffect(() => {
    if (showWarning && countdown > 0) {
      const timer = setInterval(() => {
        setCountdown((prevCountdown) => prevCountdown - 1);
      }, 1000);

      return () => clearInterval(timer); // Clear interval on dialog close or user reactivation
    } else if (countdown === 0) {
      handleLogout(); // Logout when countdown reaches 0
    }
  }, [showWarning, countdown, handleLogout]);

  return (
    <ThemeProvider>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <CssBaseline />
        {content}

        {/* Warning dialog with countdown */}
        <Dialog open={showWarning} onClose={() => setShowWarning(false)}>
          <DialogTitle>
            You've been inactive for a while. For your security, we'll automatically sign you out in approximately {countdown} seconds.
          </DialogTitle>
        </Dialog>
      </LocalizationProvider>
    </ThemeProvider>
  );
}

export default App;
